import anime, { stagger } from 'animejs';
import LocomotiveScroll from 'locomotive-scroll';

const updateVh = () => {
    let vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);
};

const isMobile = () => {
    return (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent));
};

updateVh();

if (!isMobile()) {
    window.addEventListener('resize', updateVh);
}

// Permet de préfixer les navigateurs
navigator.vibrate = navigator.vibrate || navigator.webkitVibrate || navigator.mozVibrate || navigator.msVibrate;

document.querySelector('.vibrate').addEventListener('click', () => {
    // Si l'API vibrate est supportée, on fait vibrer le téléphone 1/2 seconde
    if (navigator.vibrate) {
        // navigator.vibrate(500);
        navigator.vibrate([300, 300, 300]);
    }

    // //Il est également possible de faire vibrer plusieurs fois le téléphone en passant un tableau en paramètre :
    // if (navigator.vibrate) {
    //     navigator.vibrate([300, 300, 300]);
    // }
});


let disableTransitions =  window.innerWidth < 1200;
let scroll;

/* ------------------------------------ */
/* ------ Locomotive scroll init ------ */
/* ------------------------------------ */
const createScroll = (container) => {
    let elt = container.querySelector('[data-scroll-container]');
    let horizontalValue = elt.getAttribute('data-horizontal');
    let direction = "horizontal";// horizontalValue == 'true' ? 'horizontal' : 'vertical';
    
    // if (disableTransitions){
    //     direction = 'vertical';
    // }

    scroll = new LocomotiveScroll({
        el: elt,
        smooth: true,
        getSpeed: true,
        getDirection: true,
        direction: direction,
        gestureDirection: 'both'
    });

    return scroll;
};


/* ------------------------------------ */
/* --------- Homepage Carousel -------- */
/* ------------------------------------ */
const container = document.querySelector('[data-scroll-container]');
const items = container.querySelectorAll('[data-scroll-section]');
const prevButton = document.querySelector('.prevButton');
const nextButton = document.querySelector('.nextButton');
let currentSlideIndex = 0;
let currentSlide = null;
let prevSlide = null;
let totalSlides = 0;
let xDown = null;                                                        
let yDown = null;
let animating = false;

const carouselPrev = () => {
    if (animating) return;
    
    animating = true;

    prevSlide = currentSlide;
    currentSlideIndex = (currentSlideIndex === 0) ? totalSlides - 1 : currentSlideIndex - 1;
    currentSlide = items[currentSlideIndex];
    prevSlide.classList.remove('active');
    currentSlide.classList.add('active');

    currentSlide.querySelector('.pSection').scrollTop = 0;

    anime({
        targets: prevSlide,
        translateX: [0, '101vw'],
        opacity: [1, 0],
        duration: 1400,
        easing: 'easeInOutExpo',
        complete: () => {
            prevSlide.classList.remove('active');
        }
    });

    anime({
        targets: currentSlide,
        translateX: ['-101vw', 0],
        opacity: [0, 1],
        duration: 1400,
        easing: 'easeInOutExpo',
        complete: () => {
            currentSlide.classList.add('active');
            animating = false;
        }
    });
};

const carouselNext = () => {
    if (animating) return;

    animating = true;

    prevSlide = currentSlide;
    currentSlideIndex = (currentSlideIndex === totalSlides - 1) ? 0 : currentSlideIndex + 1;
    currentSlide = items[currentSlideIndex];

    currentSlide.querySelector('.pSection').scrollTop = 0;

    anime({
        targets: prevSlide,
        translateX: [0, '-101vw'],
        opacity: [1, 0],
        duration: 1400,
        easing: 'easeInOutExpo',
        complete: () => {
            prevSlide.classList.remove('active');
        }
    });

    anime({
        targets: currentSlide,
        translateX: ['101vw', 0],
        opacity: [0, 1],
        duration: 1400,
        easing: 'easeInOutExpo',
        complete: () => {
            currentSlide.classList.add('active');
            animating = false;
        }
    });
};

const getTouches = (evt) => {
    return evt.touches ||             // browser API
        evt.originalEvent.touches; // jQuery
}                                                     

const handleTouchStart = (evt) => {
    if (animating) return;
    const firstTouch = getTouches(evt)[0];                                      
    xDown = firstTouch.clientX;                                      
    yDown = firstTouch.clientY;                                      
};                                                

const handleTouchMove = (evt) => {
    if (animating) return;
    if ( ! xDown || ! yDown ) {
        return;
    }

    var xUp = evt.touches[0].clientX;                                    
    var yUp = evt.touches[0].clientY;

    var xDiff = xDown - xUp;
    var yDiff = yDown - yUp;

    if ( Math.abs( xDiff ) > Math.abs( yDiff ) ) {/*most significant*/
        if ( xDiff > 0 ) {
            /* left swipe */ 
            carouselNext();
        } else {
            /* right swipe */
            carouselPrev();
        }                       
    }
    // else {
    //     if ( yDiff > 0 ) {
    //         /* up swipe */ 
    //     } else { 
    //         /* down swipe */
    //     }                                                                 
    // }
    /* reset values */
    xDown = null;
    yDown = null;                                             
};

const createHomeCarousel = () => {
    totalSlides = items.length;
    currentSlide = items[currentSlideIndex];

    currentSlide.classList.add('active');

    prevButton.addEventListener('click', (e) => {
        e.preventDefault();
        carouselPrev();
    });

    nextButton.addEventListener('click', (e) => {
        e.preventDefault();
        carouselNext();
    });

    document.addEventListener('touchstart', handleTouchStart, false);        
    document.addEventListener('touchmove', handleTouchMove, false);    
}

const initPage = () => {

};

(function() {

    if (window.innerWidth > 1024) {
        scroll = createScroll(document);
    } else{
        createHomeCarousel();
    }

})();